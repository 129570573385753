/* eslint-env browser */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SearchComponent from '@aarhus-university/au-lib-react-maps/src/components/SearchComponent';
import getBuildings from '@aarhus-university/au-lib-react-maps/src/actions/getBuildings';

const App = ({ buildings, actions, map }) => {
  useEffect(() => {
    const elements = document.querySelectorAll('.find-container');
    elements.forEach((element) => {
      element.classList.add('au-map');
    });
  }, []);
  return (
    <SearchComponent
      map={map}
      lang={window.reactPageLang}
      container="au_map_building"
      width="100%"
      height={200}
      zoom={17}
      center={{
        lat: 56.168995,
        lng: 10.203113,
      }}
      scrollable={false}
      draggable={false}
      clickable={false}
      streetview={false}
      className="row"
      sideBySide={false}
      fetchLocally
      locations={buildings.Buildings}
      getLocations={actions.getBuildings}
      showMap
      showFakeMap
    />
  );
};

App.propTypes = {
  map: PropTypes.bool.isRequired,
  buildings: PropTypes.shape({
    Buildings: PropTypes.shape({

    }).isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    getBuildings: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const props = {
    buildings: state.buildings,
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getBuildings,
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

App.displayName = 'FindWidget';
export default connect(mapStateToProps, mapDispatchToProps)(App);
