/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import reducers from './reducers';
import AppComponent from './containers/AppComponent';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);
window.reactPageLang = document.querySelector('html').getAttribute('lang');

const render = (element) => {
  const noMap = element.classList.value.indexOf('no-map') > -1;
  ReactDOM.render(
    <Provider store={createStoreWithMiddleware(reducers)}>
      <AppComponent map={!noMap} />
    </Provider>,
    element,
  );
};

const findWidgetInit = (selector) => {
  const domElements = document.querySelectorAll(selector);
  domElements.forEach((domElement) => {
    render(domElement);
  });
};

window.findWidgetInit = findWidgetInit;
findWidgetInit('.content .find-container');
